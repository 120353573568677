/**
 * ATR结果映射表 -- 只读不可写
 * @field interval ATR值区间 [0,0) -- 包前不包后
 * @description badPosture -- 姿态不良的判定需要结合上一次的检测结果
 */
export const ATRResultMap = Object.freeze(new Map([
    ['normal', {
        label: '无侧弯',
        result: '一般检查和前屈试验均无异常且ATR＜5°者，为无侧弯。',
        advice: '继续保持健康行为，定期随访，参加年度筛查监测。',
        color: '#9DD447',
        interval: [0, 5],
        resultId: 0,
    }],
    ['badPosture', {
        label: '姿态不良',
        result: '一般检查异常或前屈试验阳性或ATR≥5°者，但脊柱运动试验后进行躯干旋转测量仪检查，ATR＜5°。',
        advice: '建议改变不良行为，增加体育锻炼，定期复筛。',
        color: '#5CB6FC',
        interval: [0, 5],
        resultId: 5,
    }],
    ['lateralBendingOne', {
        label: '侧弯\u0020I\u0020度',
        result: '一般检查异常或前屈试验阳性或ATR≥5°者，脊柱运动试验后进行躯干旋转测量仪检查，且5°≤ATR＜7°。',
        advice: '建议进行无辐射脊柱三维超声评估检查，如有必要，适时去医院接受进一步详细诊断。',
        color: '#F8DA37',
        interval: [5, 7],
        resultId: 1,
    }],
    ['lateralBendingTwo', {
        label: '侧弯\u0020II\u0020度',
        result: '一般检查异常或前屈试验阳性或ATR≥5°者，脊柱运动试验后进行躯干旋转测量仪检查，且7°≤ATR＜10°。',
        advice: '建议进行无辐射脊柱三维超声评估检查，如有必要，适时去医院接受进一步详细诊断。',
        color: '#FCB42E',
        interval: [7, 10],
        resultId: 2,
    }],
    ['lateralBendingThree', {
        label: '侧弯\u0020III\u0020度',
        result: '一般检查异常或前屈试验阳性或ATR≥5°者，脊柱运动试验后进行躯干旋转测量仪检查，且ATR≥10°。',
        advice: '建议进行无辐射脊柱三维超声评估检查，如有必要，适时去医院接受进一步详细诊断。',
        color: '#FB8346',
        interval: [10, Infinity],
        resultId: 3,
    }],
]));

/**
 * ATR结果id和名称映射表 -- 只读不可写
 */
export const resultIdMapLabel = Object.freeze(Object.fromEntries([...ATRResultMap].map(item => {
    return [item[1].resultId, item[1].label]
})));

/**
 * 参考依据 -- 只读不可写
 */
export const ATRResultReference = Object.freeze({
    label: '参考依据',
    source: [
        'ATR参考依据来源：国家卫生健康委员会，《儿童青少年脊柱侧弯异常防控技术指南》，2021年11月1日。',
        '“活动度”参考依据来源：《康复功能评定学》第3版；',
        '“脊柱后凸”参考依据来源：《青少年特发性脊柱侧凸的评估与治疗》。',
    ],
});

/**
 * 检测类型对应的检测项目 -- 只读不可写
 */
export const examineTypeMapProject = Object.freeze({
    quick: '躯干旋转角ATR快速筛查',
    normal: '分段ATR、脊柱后凸、侧向弯曲、前屈后伸、旋转角、身体平衡度',
});

/**
 * 常规检测部位映射表
 */
export const normalExamineBodyMap = Object.freeze(new Map([
    ['thoracicSegment', { label: '胸段ATR' }],
    ['lumbarSegment', { label: '腰段ATR' }],
    ['thoracolumbar', { label: '胸腰段ATR' }],
    ['freeMode', { label: '自由模式' }],
    ['lateralBending', {
        label: '侧向弯曲',
        body: { neck: '颈部', thoracic: '胸部', lumbar: '腰部' },
    }],
    ['bodyBalance', {
        label: '身体平衡度',
        body: { head: '头部', shoulder: '肩部', lumbar: '腰部' },
    }],
    ['hunchbackAngle', { label: '脊柱后凸' }],
    ['flexionExtension', {
        label: '前屈后伸',
        body: { neck: '颈部', thoracic: '胸部', lumbar: '腰部' },
    }],
    ['rotationAngle', {
        label: '旋转角',
        body: { neck: '颈部', thoracic: '胸部', lumbar: '腰部' },
    }],
]));

// 初始化数据
const initExamineOrgInfoList = [{ label: '筛查执行机构', value: '' }, { label: '联系电话', value: '' }];

/**
 * 获取性别
 * @param gender 
 * @returns 
 */
export const getGender = (gender: 'm' | 'f' | '') => {
    switch (gender) {
        case 'm':
            return '男'
        case 'f':
            return '女'
    }
    return '其他'
}

/**
 * 根据生日获取年龄
 * @param birthDate YYYY-MM-DD
 * @returns 
 */
export const getAge = (birthDate: string) => {
    return birthDate ? new Date(Date.now() - new Date(birthDate).getTime()).getFullYear() - 1970 : 0;
}

/**
 * 获取左右测量结果
 * @param data 
 */
export const getLeftRightResult = (data: (number | string)[]) => {
    // 兼容处理
    if (data.length < 2) {
        return { leftAngle: 0, rightAngle: 0, diagnosisResult: '--' };
    }

    const num0 = +data[0];
    const num1 = +data[1];
    const absNum0 = Math.abs(num0);
    const absNum1 = Math.abs(num1);

    /* 左右值绝对值相等 */
    if (absNum0 === absNum1) {
        return { leftAngle: absNum0, rightAngle: absNum1, diagnosisResult: '左右平衡' };
    }

    // num0为左
    if (num0 > num1) {
        if (absNum0 > absNum1) {
            return { leftAngle: absNum0, rightAngle: absNum1, diagnosisResult: '左高右低' };
        } else {
            return { leftAngle: absNum0, rightAngle: absNum1, diagnosisResult: '左低右高' };
        }
    }

    // num1为左
    if (absNum0 > absNum1) {
        return { leftAngle: absNum1, rightAngle: absNum0, diagnosisResult: '左低右高' };
    } else {
        return { leftAngle: absNum1, rightAngle: absNum0, diagnosisResult: '左高右低' };
    }
}

/**
 * 获取 快速筛查 ATR 诊断结果
 * @param data 
 * @param exerciseStatus 
 * @returns 
 */
export const getQuickATRResult = (data: string[], exerciseStatus?: boolean) => {
    // 转换类型
    const quickData = data.map(item => Number(item));
    // 获取 左边和右边 中的最大值
    const absMaxData = Math.max(Math.abs(quickData[0]), Math.abs(quickData[1]));

    // 条件分发
    if (absMaxData < 5) {
        if (!exerciseStatus) {
            return { key: 'normal', angle: absMaxData } // 无侧弯 
        }
        return { key: 'badPosture', angle: absMaxData }  // 姿态不良
    }
    if (absMaxData >= 5 && absMaxData < 7) {
        return { key: 'lateralBendingOne', angle: absMaxData } // 侧弯Ⅰ度
    }
    if (absMaxData >= 7 && absMaxData < 10) {
        return { key: 'lateralBendingTwo', angle: absMaxData } // 侧弯Ⅱ度
    }
    if (absMaxData >= 10) {
        return { key: 'lateralBendingThree', angle: absMaxData } // 侧弯Ⅲ度
    }
    return { key: '', angle: 0 }
};

/**
 * 获取快筛数据
 * @description 检测结果第一项是快筛数据
 * @param examineJSON 
 * @returns 
 */
export const getQuickData = (examineJSON?: string) => {
    if (!examineJSON) return;
    return JSON.parse(examineJSON)[0] as Report.TExamineData;
}

/**
 * 获取常规数据
 * @description 检测结果除第一项是常规数据
 * @param examineJSON 
 * @returns 
 */
export const getNormalData = (examineJSON?: string) => {
    if (!examineJSON) return;
    return (JSON.parse(examineJSON) as Report.TExamineData[]).slice(1);
}

/**
 * 分段结果
 * @param maxAngleList 
 * @returns 
 */
export const getSegmentResult = (maxAngleList: number[]) => {
    const segmentResult = {
        maxAngle: 0,
        direction: 'left' as 'left' | 'right',
    }
    maxAngleList.forEach(num => {
        const numAbs = Math.abs(num);
        if (numAbs > segmentResult.maxAngle) {
            segmentResult.maxAngle = numAbs;
            segmentResult.direction = num >= 0 ? 'left' : 'right';
        }
    });
    return segmentResult;
}

/**
 * 获取ATR分段最大值和最小值
 * @description 脊柱左右旋转的最大值
 * @param dataList 
 * @returns 
 */
export const getLeftRightMaxValue = (dataList: number[]) => {
    const maxNum = Math.max(...dataList);
    const minNum = Math.min(...dataList);
    return [maxNum > 0 ? maxNum : 0, minNum < 0 ? minNum : 0];
}

/**
 * 获取绝对值最大值
 */
export const getAbsMax = (list: number[]) => {
    let targetNum = 0;
    list.forEach(num => {
        if (Math.abs(num) > Math.abs(targetNum)) {
            targetNum = num;
        }
    });
    return targetNum;
}

/**
 * 获取报告设置中请求体/响应体的评估等级字段
 * @description 多了evaluation前缀, 以及首字母大写
 * @description evaluationBadPosture evaluationLateralBendin1 evaluationLateralBendin2 evaluationLateralBendin3 evaluationNormal
 * @param key 
 * @returns 
 */
export const getEvaluationLevel = <T extends string = string>(key: string) => {
    return 'evaluation' + ((key.slice(0, 1).toLocaleUpperCase()) + (key.slice(1))) as T;
}

/**
 * 获取初始的检测机构信息
 * @param reportInfo 
 * @returns 
 */
export const getInitExamineOrgInfoList = (reportInfo?: API.TGetReportTemplateInfoResponseData) => {
    if (reportInfo?.examineOrgInfo) {
        const resultList = JSON.parse(reportInfo.examineOrgInfo) as { label: string, value: string }[];
        return resultList.length ? resultList : initExamineOrgInfoList
    }
    return initExamineOrgInfoList;
}

/**
 * 获取初始的症状等级及对应建议列表
 * @returns 
 */
export const getInitLevelAdviceList = (reportInfo: API.TGetReportTemplateInfoResponseData) => {
    // 转数组 -- 用来判断空值
    const reportInfoKeys = Object.keys(reportInfo);

    // 空值返回默认值
    if (reportInfoKeys.length === 0) {
        return [...ATRResultMap].map(item => {
            return {
                key: item[0],
                label: item[1].label,
                advice: item[1].advice || '',
            }
        })
    } else {
        // 有值的返回, 没有的返回默认 ` || item[1].advice || '' `
        return [...ATRResultMap].map(item => {
            return {
                key: item[0],
                label: item[1].label,
                advice: reportInfo[getEvaluationLevel(item[0]) as keyof typeof reportInfo] as string || item[1].advice || '',
            }
        })
    }
}

/**
 * 获取元素id和pdf标题
 * @param historyData 
 * @returns 
 */
export const getElementIdAndPDFTitle = (historyData?: API.TExamineHistoryRespData, elementId?: string) => {
    if (!historyData) {
        return { title: '', elementId: '' }
    }
    const { schoolName, clazzName, gradeName, name, id, typeId } = historyData;
    let type: 'quick' | 'normal' = typeId === 0 ? 'quick' : 'normal';

    const title = (schoolName || '学校') + '-' + (gradeName || '年级') + '-' + (clazzName || '班级') + '-' + (name || '姓名');
    elementId ||= type + '-report-container-' + id;

    return { title, elementId }
}