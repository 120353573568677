import * as ActionTypes from '../ActionTypes';
import { reduxSearchData } from "../../utils/dataUtils"
import { orgSearch } from '../../pages/organization/utils';
function orgs(
  state = {
    orgsList: { list: [], total: 0 },
    orgsList_DV: { list: [], total: 0 },
    // v1.0.3-rd-dv
    shcoolList: { list: [], total: 0 },
    orgsSearch: reduxSearchData(orgSearch)
  },
  action
) {
  switch (action.type) {
    // 修改机构列表
    case ActionTypes.SET_ORGS_LIST:
      return {
        ...state,
        orgsList: action.data
      };
    // 修改搜索机构列表
    case ActionTypes.SET_ORGS_SEARCH:
      return {
        ...state,
        orgsSearch: { ...state.orgsSearch, ...action.data }
      };
    // 存储 orgsList_DV
    case ActionTypes.SET_ORGS_LIST_DV:
      return {
        ...state,
        orgsList_DV: { ...state.orgsList_DV, ...action.data }
      };
    // 存储 学校列表
    case ActionTypes.SET_SCHOOL_LIST:
      return {
        ...state,
        shcoolList: action.data
      };

    default:
      return state;
  }
}

export default orgs;
