import { Tag, Tooltip } from 'antd';
import intl from 'react-intl-universal';
import { getProjectStatusStr } from "../../../redux/selecters/platform.js"
import store from '../../../store';
import SchoolsLink from '../components/SchoolsLink';

export const preProjectInfo = { name: '' }
export const projectSearch = [
    {
        name: 'name',
        label: "PROJECT.NAME",
        value: "",
        type: "input",
        defaultValue: "",
        colSpan: 5,
        offset: 0,
        maxLength: 30
    },
    {
        name: 'status',
        label: "PROJECT.STATUS",
        value: "",
        type: "select",
        options: [],
        colSpan: 3,
        offset: 1,
    }

]

const proTagColor = ['gray', 'green', 'red']

export function projectsColumns() {
    return [
        {
            title: intl.get('PROJECT.NAME'),
            dataIndex: 'name',
            width: 120,
            align: 'center'
        },
        {
            title: intl.get('PROJECT.STATUS'),
            dataIndex: 'status',
            width: 120,
            align: 'center',
            render: (_: any, { status }: { status: string | number | undefined }) => {
                const index = +(status || 0)
                return <Tag className={index + ''} color={proTagColor[index]}>
                    {getProjectStatusStr(store.getState())[index]}
                </Tag>
            }
        },
        {
            title: intl.get('PROJECT.SCHOOLS'), // 关联学校/科室
            dataIndex: 'schoolName',
            width: 120,
            align: 'center',
            render: (_: any, record: { schoolName: any[]; }) =>
                <Tooltip placement="topLeft" title={record.schoolName?.join(",")}>
                    <div className='project_schoolName' >{record.schoolName?.join(",")}</div>
                </Tooltip >
        },
    ];
}

export function projectForm() {
    return [
        {
            name: 'name',
            label: "PROJECT.NAME",
            value: "",
            type: "input",
            defaultValue: "",
            maxLength: 30,
            message: "PROJECT.NAME_MSG",
            rules: [
                {
                    required: true,
                    message: intl.get("COMMON.IS_REQUIRED"),
                },
            ]
        }
    ]
}

/**
 * 编辑项目表单 form 组件配置
 * @returns 
 */
export function editProjectForm() {
    return [
        {
            name: 'name',
            label: "PROJECT.NAME",
            value: "",
            type: "input",
            defaultValue: "",
            maxLength: 30,
            message: "PROJECT.NAME_MSG",
            rules: [
                {
                    required: true,
                    message: intl.get("PROJECT.NAME_MSG"),
                },
            ]
        },
        // 关联学校/科室
        {
            type: "schoolsLinkSelect",
            component: SchoolsLink
        },

        // 项目状态
        {
            name: 'status',
            key: "status",
            label: "PROJECT.STATUS",
            value: "",
            type: "radio",
            message: "PROJECT.STATUS",
            warn: "PROJECT.STATUS_WARN",
            rules: [
                {
                    required: true,
                    message: intl.get("PROJECT.NAME_MSG"),
                },
            ]
        }
    ]

}