

import { SET_PROJECT_SEARCH, SET_PROJECTS_LIST, SET_PROJECTS_LIST_DV } from '../ActionTypes'
import { apiGetProjectList, apiAddProject, apiEditProject, apiDelProject } from "../../actions/api"
import { takeLatest, cancelled, call, put } from 'redux-saga/effects';
import store from '../../store';
import { delSearchNull } from '../../utils/dataUtils'
import { cloneDeep } from "lodash"
import { message } from 'antd'
import { isHadLogin } from "./platform.js"
import intl from 'react-intl-universal';


export const setProjectsSearch = (data) => {
    return {
        type: SET_PROJECT_SEARCH,
        data
    }
}
export const setProjectsList = (data) => {
    return {
        type: SET_PROJECTS_LIST,
        data
    }
}

/**
 * 存储项目数据到仓库
 * @param {*} data 
 * @returns 
 */
export const setProjectsList_DV = (data) => {
    return {
        type: SET_PROJECTS_LIST_DV,
        data
    }
}


/*************************************************************************************** */
export function* watchSwitchProjectsSearch() {
    yield takeLatest('SET_PROJECT_SEARCH', getProjectsList);
}

export function* getProjectsList() {
    let search = delSearchNull(store.getState().projects.projectsSearch)
    if (isHadLogin()) {
        try {
            const data = yield call(apiGetProjectList, search);
            const Elist = {
                list: formProList(data.rows),
                total: data.total
            };
            yield put(setProjectsList(Elist))
        } catch {
            cancelled()
        } finally {

        }
    }
}

export function getProjectsList_DV() {
    /**
     * dispatch 派发函数时
     * 1. 函数必须需要有返回一个返回值
     *      返回值 是一个非函数类型的数据则需要返回 action 动作名
     *      返回值 是一个函数类型的数据不用其余操作
     *          函数有两个形参:
     *          1. dispatch 派发动作函数
     *          2. getState 获取状态函数
     */
    return async (dispatch, _getState) => {
        try {
            // 调取接口
            const data = await apiGetProjectList({ size: 1000 });

            // 组装数据结构
            const projectsList = {
                total: data.total,
                list: data.rows.map(item => {
                    // 接口返回的 schools 字段数据是 JSON 格式, 需要先解析
                    const schoolParse = JSON.parse(item.schools || '[]').map(item => String(item));
                    // 获取 学校名称
                    const schoolNameList = Object.values(item.schoolsStr || {});

                    // 返回处理后的数据
                    return {
                        ...item,
                        schools: schoolParse,
                        schoolName: schoolNameList
                    }
                })
            };

            // 派发存储动作
            dispatch(setProjectsList_DV(projectsList))
        }
        catch (error) { }
    }
}

/**
 * 
 * @param {*} type "all"和"search" 全部和搜索条件查询
 * @returns 
 */
export function getProjectsListBySearch(type) {
    let search = type === 'all' ? { size: 1000 } : delSearchNull(store.getState().projects.projectsSearch)
    return function (dispatch) {
        apiGetProjectList(search)
            .then(data => {
                const Elist = {
                    list: formProList(data.rows),
                    total: data.total
                };
                dispatch(setProjectsList(Elist))
            }).catch(() => { })
    }
}



export function addProject(data, updateModal) {
    return function (dispatch) {
        apiAddProject(data)
            .then(() => {
                message.success(intl.get("ACTION.ADD_SUCCESS"))
                dispatch(getProjectsListBySearch('search'))
                updateModal.current.handleCancel();
            }).catch(() => { })
    }
}


export function editProject(data, updateModal) {
    // 数组 转 JSON格式
    Reflect.set(data, "schools", JSON.stringify(data.schools))
    // 删除接口不需要参数
    Reflect.deleteProperty(data, "PCD");
    Reflect.deleteProperty(data, "index");
    Reflect.deleteProperty(data, "schoolName");
    Reflect.deleteProperty(data, "schoolsStr");
    Reflect.deleteProperty(data, "statusStr");

    // 返回一个含有派发器的函数并立即执行
    return function (dispatch) {
        apiEditProject(data)
            .then(() => {
                message.success(intl.get("ACTION.EDIT_SUCCESS"))
                dispatch(getProjectsListBySearch('search'))
                updateModal.current.handleCancel();
            }).catch(() => { })
    }
}

export function delProjectById(id) {
    return function (dispatch) {
        apiDelProject(id)
            .then(() => {
                message.success(intl.get("ACTION.DEL_SUCCESS"))
                dispatch(getProjectsListBySearch('search'))
            }).catch(() => { })
    }
}

export function formProList(list) {
    let l = cloneDeep(list)
    l.map(item => {
        let schoolName = []
        item.schools = JSON.parse(item.schools ? item.schools : '[]')

        // 如果是数组, 转换为字符串数组
        if (Object.prototype.toString.call(item.schools) === '[object Array]') {
            item.schools = item.schools.map(item => String(item));
        }

        for (let key in item.schoolsStr) {
            schoolName.push(item.schoolsStr[key])
        }
        item.schoolName = schoolName
        return item
    })
    return l
}