// 导入 axios 实例
import request from '@/utils/request';

/**
 * 请求头设置方式
 * $ POST:
 *  request.post('/api/v1/users/login', data, { headers: { name: '123214' } })
 * $ GET: 
 *  request.get('/api/v1/users/login', { data, headers: { name: '123214' } })
 * 
 * 配置项其他属性不会被覆盖 而是进行属性合并
 */


// 登录相关 *************************************************************

/**
 * 用户名登录
 * @param {*} data 
 * @returns 
 */
export function apiLogin(data) {
    return request.post('/api/v1/users/login', data)
}

// request.get("v1/management/allprojects")
//     .then((res) => {
//         console.log('res', res);
//     })

/**
 * 用户名登录所需 验证码
 * @param {*} data 
 * @returns 
 */
export function apiGetPicCode(data) {
    return request.post('/api/v1/users/picCode', data)
}

/**
 * 手机号登录
 * @param {*} data 
 * @returns 
 */
export function apiloginSms(data) {
    return request.post('/api/v1/users/loginSms', data)
}

/**
 * 发送手机验证码
 * @param {*} data 
 * @returns 
 */
export function apiSendSms(data) {
    return request.post('/api/v1/users/sendSms', data)
}

/**
 * 登出
 * @returns 
 */
export function apiLogout() {
    return request.post('/api/v1/users/logout')
}

/**
 * 修改用户密码
 * @param {*} data 
 * @returns 
 */
export function apiEditPwd(data) {
    return request.post('/api/v1/users/changePw', data)
}

/**
 * 绑定手机号时, 校验手机号和验证码
 * @param {*} data 
 * @returns 
 */
export function apiCheckTelAndSms(data) {
    return request.post('/api/v1/users/checkTelSms', data)
}

// 检查记录相关 *************************************************************

/**
 * 检查记录列表
 * @param {*} params 
 * @returns 
 */
export function apiGetExamsList(params) {
    return request.get('/api/v1/exams/list', { params })
}

// 医疗机构相关 *************************************************************

/**
 * 医疗机构列表
 * @param {*} params 
 * @returns 
 */
export function apiGetOrgsList(params) {
    return request.get('/api/v1/org/list', { params })
}

/**
 * 添加医疗机构
 * @param {*} data 
 * @returns 
 */
export function apiAddOrg(data) {
    return request.post('/api/v1/org/add', data)
}

/**
 * 修改医疗机构
 * @param {*} data 
 * @returns 
 */
export function apiEditOrg(data) {
    return request.post('/api/v1/org/edit', data)
}

/**
 * 删除医疗机构
 * @param {*} data 
 * @returns 
 */
export function apiDelOrg(params) {
    return request.post('/api/v1/org/del', null, { params })
}

// 用户相关 *************************************************************


/**
 * 用户列表
 * @param {*} params 
 * @returns 
 */
export function apiGetUsersList(params) {
    return request.get('/api/v1/users/list', { params })
}

/**
 * 添加用户
 * @param {*} data 
 * @returns 
 */
export function apiAddUser(data) {
    return request.post('/api/v1/users/add', data)
}

/**
 * 编辑用户
 * @param {*} data 
 * @returns 
 */
export function apiEditUser(data) {
    return request.post('/api/v1/users/edit', data)
}

/**
 * 删除用户
 * @param {*} params 
 * @returns 
 */
export function apiDelUser(params) {
    return request.post('/api/v1/users/del', null, { params })
}

/**
 * 重置密码
 * @param {*} data 
 * @returns 
 */
export function apiResetPwd(data) {
    return request.post('/api/v1/users/resetPw', data)
}

// 患者/受检人列表,上传相关 *************************************************************


/**
 * 患者/受检人列表
 * @param {*} params 
 * @returns 
 */
export function apiGetPatientsList(params) {
    return request.get('/api/v1/patient/list', { params })
}

/**
 * 上传文件
 * @param {*} fileType 
 * @param {*} schoolId 
 * @param {*} data 
 * @returns 
 */
export function apiUpload(fileType, schoolId, data) {
    return request.post('/api/v1/import/excel', data,
        {
            headers: { 'Content-Type': 'multipart/form-data' },
            params: { schoolId }
        })
}

/**
 * 删除患者/受检人列表
 * @param {*} params 
 * @returns 
 */
export function apiDelPatient(params) {
    return request.post('/api/v1/patient/del', null, { params })
}

/**
 * 添加患者/受检人
 * @param {*} data 
 * @returns 
 */
export function apiAddPatient(data) {
    return request.post('/api/v1/patient/add', data)
}

/**
 * 修改患者/受检人
 * @param {*} data 
 * @returns 
 */
export function apiEditPatient(data) {
    return request.post('/api/v1/patient/edit', data)
}

// 学校/科室相关 *************************************************************

/**
 * 查询学校/科室
 * @param {*} params 
 * @returns 
 */
export function apiGetSchoolsList(params) {
    return request.get('/api/v1/school/list', { params })
}

/**
 * 添加学校/科室
 * @param {*} data 
 * @returns 
 */
export function apiAddSchool(data) {
    return request.post('/api/v1/school/add', data)
}

/**
 * 修改学校/科室
 * @param {*} data 
 * @returns 
 */
export function apiEditSchool(data) {
    return request.post('/api/v1/school/edit', data)
}

/**
 * 删除学校/科室
 * @param {*} params 
 * @returns 
 */
export function apiDelSchool(data) {
    return request.delete('/api/v1/school/del/'+data)
}

// 项目相关 *************************************************************

/**
 * 查询项目
 * @param {*} params 
 * @returns 
 */
export function apiGetProjectList(params) {
    return request.get('/api/v1/project/list', { params })
}

/**
 * 添加项目
 * @param {*} data 
 * @returns 
 */
export function apiAddProject(data) {
    return request.post('/api/v1/project/add', data)
}

/**
 * 编辑项目
 * @param {*} data 
 * @returns 
 */
export function apiEditProject(data) {
    return request.post('/api/v1/project/edit', data)
}

/**
 * 删除项目
 * @param {*} data 
 * @returns 
 */
export function apiDelProject(data) {
    return request.delete('/api/v1/project/del/'+data)
}

// 数据统计相关 *************************************************************

/**
 * 医生机构筛查进度
 * @param {*} params 
 * @returns 
 */
export function apiGetProjectProgress(params) {
    return request.get('/api/v1/stat/progress', { params })
}

/**
 * 医生机构比例统计图表
 * @param {*} params 
 * @returns 
 */
export function apiGetExamsRes(params) {
    return request.get('/api/v1/stat/rate', { params })
}

/**
 * 医生机构年龄图表
 * @param {*} params 
 * @returns 
 */
export function apiGetExamsByAge(params) {
    return request.get('/api/v1/stat/age', { params })
}

/**
 * 根据学校状态获取检查记录
 * @param {*} params 
 * @returns 
 */
export function apiGetExamsBySchool(params) {
    return request.get('/api/v1/exam/school_status', { params })
}

/**
 * 医生机构性别图表
 * @param {*} params 
 * @returns 
 */
export function apiGetExamsByGender(params) {
    return request.get('/api/v1/stat/gender', { params })
}

// 服务器ip相关 ********************************************************

/**
 * 获取服务器ip
 * @returns 
 */
export function apiGetIp() {
    return request.get('/api/ipinfo/')
}

// _201的报告相关 ********************************************************

/**
 * 获取 201检测图片
 * @param {*} params 
 * @returns 
 */
export function apiGet201Img(params) {
    return request.get('/api/v1/patientReport/listExamImage', { params })
}

/**
 * 获取 201报告图片
 * @param {*} params 
 * @returns 
 */
export function apiGet201Report(params) {
    return request.get('/api/v1/patientReport/listReportImage', { params })
}

// 蓝牙码相关 *************************************************************

/**
 * 生产蓝牙码
 * @param {*} params 
 * @returns 
 */
export function apiCreateBlueCode(params) {
    return request.post('/api/v1/numblue/create', null, { params })
}

/**
 * 获取蓝牙码
 * @param {*} params 
 * @returns 
 */
export function apiGetBlueCodeList(params) {
    return request.get('/api/v1/numblue/list', { params })
}

/**
 * 下载蓝牙码
 * @returns 
 */
export function apidownloadBtCode() {
    return request.get('/api/v1/numblue/download')
}

// 日志相关 ***************************************************** 

/**
 * 获取日志
 * @param {*} params 
 * @returns 
 */
export function apiGetLogList(params) {
    return request.get('/api/v1/log/list', { params })
}

/**
 * 获取检测记录
 */
export const getExamineHistory = (params) => {
    return request.get('api/v1/exams/history/list', { params })
}

/**
 * 分页获取动作库
 */
export const apietActionWarehouseList = (params) => {
    return request.get('api/motion/warehouse/page/list', { params })
}
/**
 * 新增动作库的动作
 */
export function apiAddActionWarehouse(data) {
    return request.post('/api/motion/warehouse/save', data)
}
/**
 * 修改动作库的动作
 */
export function apiEditActionWarehouse(data) {
    return request.post('/api/motion/warehouse/edit', data)
}
/**
 * 刪除动作库的动作
 */
export function apiDelActionWarehouse(data) {
    return request.post('/api/motion/warehouse/del?motionId=' + data)
}

/**
 * 上传运动视频
 * @param {*} data 
 * @returns 
 */
export function apiUploadVideo(data) {
    return request.post('/api/motion/warehouse/upload', data,
        {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
}

// 开具处方 ***************************************************** 
/**
 * 分页获取患者
 */
export const apigetExePatList = (params) => {
    return request.get('api/v1/exams/page/list', { params })
}

/**
 * 分页查询处方
 */
export const apigetExePrescriptionList = (params) => {
    return request.get('api/sports/recipe/page/list', { params })
}

/**
 * 针对患者新增处方
 */
export function apiaddExePrescription(data) {
    return request.post('api/sports/recipe/save', data)
}

/**
 * 根据患者id获取患者信息（开具处方页面）
 */
export function apiGetPatInfoById(params) {
    return request.get('api/sports/recipe/issue', { params })
}

/**
 * 开具处方/编辑处方
 */
export function apiEditExePrescription(data) {
    return request.post('api/sports/recipe/edit', data)
}


/**
 *詳情
 */
export function apiGetExePrescriptionInfoById(params) {
    return request.get('api/sports/recipe/info', { params })
}
// 系统处方 ***************************************************** 
/**
 * 分页查询处方
 */
export const apiGetSysPrescriptionList = (params) => {
    return request.get('api/dtx/prevent/train/option/page/list', { params })
}

/**
 * 新增系统处方
 */
export function apiAddSystemExePrescription(data) {
    return request.post('api/dtx/prevent/train/option/add', data)
}
/**
 * 上传封面
 * @param {*} data 
 * @returns 
 */
export function apiUploadCover(data) {
    return request.post('api/dtx/prevent/train/option/upload', data,
        {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
}
/**
 * 查詢所有的系统处方
 */
export const apiGetAllSysPrescriptionList = (params) => {
    return request.get('api/dtx/prevent/train/option/list', { params })
}

/**
 * 根据id查询系统处方详情
 */
export const apiGetSysPrescriptionById = (params) => {
    return request.get('api/dtx/prevent/train/option/info', { params })
}

/**
 * 编辑系统处方
 */
export function apiEditSystemExePrescription(data) {
    return request.post('api/dtx/prevent/train/option/edit', data)
}
