/**
 * 公司官网
 */
export const officialWebsite = "https://www.scolioscan.com/";
/**
 * 设备基础类型
 */
export const baseDeviceType = "101";
/**
 * 版本号
 */
export const frontEndReleasesVersion = "V1";
/**
 * reg：注册版本, dev:开发版本, RD:研发版本
 */
export const frontVersionType = 'reg';
/**
 * 开发测换环境版本号
 */
export const frontEndVersion = "V 1.0.1.09121516";

/**
 * 正式版版本号
 */
// export const frontEndVersion = "V 1.0.1";